import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoginModalVisibility } from "slices/globalSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCirclePlay,
  faHeart,
  faUser,
  faCheck,
  faCalendarAlt,
  faMessage,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const BottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    if (location.pathname.startsWith("/browse") && path === "/browse") {
      return true;
    }

    return location.pathname === path;
  };

  const handleNavigation = (to) => {
    if (to === "/following" && !isAuthenticated) {
      dispatch(setLoginModalVisibility(true));
      return;
    }
    navigate(to);
  };

  const NavItem = ({ to, icon, text }) => {
    const activeClass = isActive(to) ? "active nav-link" : "nav-link";
    return (
      <li className={activeClass} onClick={() => handleNavigation(to)}>
        <FontAwesomeIcon icon={icon} className="icon" />
        <span className="nav-text">{text}</span>
      </li>
    );
  };

  const renderDashboardMenu = () => (
    <ul className="menu-links">
      <NavItem to="/dashboard/profile" icon={faUser} text="My Profile" />
      {user?.is_host && (
        <>
          <NavItem
            to="/dashboard/appointments"
            icon={faCheck}
            text="Appointments"
          />
          <NavItem
            to="/dashboard/host-session"
            icon={faCalendarAlt}
            text="Host Session"
          />
        </>
      )}
      <NavItem to="/dashboard/messages" icon={faMessage} text="Messages" />
      <NavItem to="/dashboard/password" icon={faLock} text="Password" />
    </ul>
  );

  const renderDefaultMenu = () => (
    <ul className="menu-links">
      <NavItem to="/" icon={faHome} text="Home" />
      <NavItem to="/browse" icon={faCirclePlay} text="Browse" />
      <NavItem to="/following" icon={faHeart} text="Following" />
      {isAuthenticated && (
        <NavItem to="/friends" icon={faUser} text="Friends" />
      )}
    </ul>
  );

  return (
    <div className="bottom-nav">
      {location.pathname.startsWith("/dashboard")
        ? renderDashboardMenu()
        : renderDefaultMenu()}
    </div>
  );
};

export default BottomNav;
