import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetProposedSessionInfoQuery } from "api/sessionApi";
import Loader from "components/Loader";
import Checkout from "components/Checkout";
import moment from "moment";
import "./styles.scss";

const ProposedSessionPayment = () => {
  const navigate = useNavigate();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const { data, isLoading, isError } = useGetProposedSessionInfoQuery(id);

  if (data?.session?.initiator?.id !== loggedInUser.id) {
    return (
      <p className="content-body bid-info-container">
        You are not authorized to view this page.
      </p>
    );
  }

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (isError)
    return (
      <p className="content-body bid-info-container">
        Error fetching proposed session information.
      </p>
    );

  const startHour = moment(data?.session?.start_time, "h:mm a").hour();
  const endHour = moment(data?.session?.end_time, "h:mm a").hour();
  let startFormatted = new Date(0, 0, 0, startHour, 0).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  let endFormatted = new Date(0, 0, 0, endHour, 0).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    data && (
      <div className="content-body bid-info-container">
        <h2>Proposed Session Information</h2>
        <p>
          <strong>Session Title:</strong> <span>{data.session.title}</span>
        </p>
        <p>
          <strong>Initiator Name:</strong>{" "}
          <span>{data.session.initiator.name}</span>
        </p>
        <p>
          <strong>Date:</strong> <span>{data.session.session_date}</span>
        </p>
        <p>
          <strong>Time:</strong>
          <span>
            {startFormatted} - {endFormatted}
          </span>
        </p>
        <p>
          <strong>Price:</strong>
          <span>$ {data.session.price}</span>
        </p>
        <div className="bid-info-actions">
          <button
            className="btn accept-btn"
            onClick={() => setShowCheckoutModal(true)}
          >
            Proceed to Checkout
          </button>
        </div>
        {showCheckoutModal && (
          <Checkout
            show={showCheckoutModal}
            selectedSession={{ ...data.session }}
            refetchUser={() => navigate("/")}
            handleClose={() => setShowCheckoutModal(false)}
          />
        )}
      </div>
    )
  );
};

export default ProposedSessionPayment;
