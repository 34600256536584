import React, { useState, useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import moment from "moment";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

dayjs.extend(isBetween);

const MainCalendar = ({
  sessions,
  bookedSession,
  handleEventClick,
  showAddSessionBtn,
  setShowSessionModal,
  setShowProposeSessionModal,
  selectedCalendarDate,
  setSessionFormData,
  isAuthenticated,
  isHost,
}) => {
  const [currentWeek, setCurrentWeek] = useState(moment());
  const [isMobile, setIsMobile] = useState(false);
  const today = moment();
  const [currentTime, setCurrentTime] = useState(moment());
  const startDate = +moment();
  const [formattedDate, setFormattedDate] = useState(
    moment(startDate).format("MMMM YYYY")
  );
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selectedCalendarDate !== null) {
      const selectedDate = moment()
        .year(selectedCalendarDate.year)
        .month(selectedCalendarDate.month)
        .date(selectedCalendarDate.day);

      const startOfSelectedWeek = selectedDate.startOf("week");
      setCurrentWeek(startOfSelectedWeek);
      setFormattedDate(moment(selectedDate).format("MMMM YYYY"));
    }
  }, [selectedCalendarDate]);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 60000);

    return () => clearInterval(interval);
  }, []);

  const startOfWeek = currentWeek.clone().startOf("week").add(1, "day");
  const daysOfWeek = Array.from({ length: isMobile ? 5 : 7 }).map((_, i) =>
    startOfWeek.clone().add(i, "days")
  );

  const handleNextWeek = () => {
    const nextStart = currentWeek.clone().add(isMobile ? 5 : 7, "days");
    setCurrentWeek(nextStart);
  };

  const handlePrevWeek = () => {
    const prevStart = currentWeek.clone().subtract(isMobile ? 5 : 7, "days");
    setCurrentWeek(prevStart);
  };
  const goToToday = () => setCurrentWeek(moment());

  const getSelectedDateTime = (day, hour) => {
    return dayjs(day.format("YYYY-MM-DDTHH:mm:ss")).hour(hour);
  };

  const isPastDayTimeSelect = (selectedDateTime) => {
    return selectedDateTime.isBefore(currentTime);
  };

  const handleMouseDown = (day, hour) => {
    if (!isAuthenticated || !showAddSessionBtn) return;

    const selectedDateTime = getSelectedDateTime(day, hour);
    if (isPastDayTimeSelect(selectedDateTime)) return;

    setIsSelecting(true);
    setSelectionRange({ start: { day: selectedDateTime, hour }, end: null });
  };

  const handleMouseEnter = (day, hour) => {
    if (!isAuthenticated || !showAddSessionBtn) return;

    const selectedDateTime = getSelectedDateTime(day, hour);
    if (isPastDayTimeSelect(selectedDateTime)) return;

    if (isSelecting) {
      setSelectionRange({
        start: selectionRange.start,
        end: { day: selectedDateTime, hour },
      });
    }
  };

  const handleMouseUp = () => {
    if (!isAuthenticated || !showAddSessionBtn) return;

    setIsSelecting(false);
    if (selectionRange) {
      const { start, end } = selectionRange;
      const startDateTime = start.day;
      const endDateTime = end?.day
        ? end.day
        : getSelectedDateTime(start.day, start.hour + 1);

      const formattedDate = startDateTime.format("YYYY-MM-DD");
      const formattedStartTime = startDateTime.format("HH:mm");
      const formattedEndTime = endDateTime.format("HH:mm");

      setSessionFormData({
        date: formattedDate,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      });
      setShowSessionModal();
      setSelectionRange(null);
    }
  };

  const isSelectedCell = (day, hour) => {
    const { start, end } = selectionRange;
    const startDateTime = start.day;
    const endDateTime = end?.day
      ? end.day
      : getSelectedDateTime(start.day, start.hour + 1);
    const selectedDateTime = getSelectedDateTime(day, hour);
    const isInBetween = selectedDateTime.isBetween(
      startDateTime,
      endDateTime,
      null,
      "[)"
    );
    return isInBetween ? "selected" : "";
  };

  const renderEvents = (day) => {
    const dayEvents = sessions.filter((event) =>
      moment(event.session_date).isSame(day, "day")
    );

    return dayEvents.map((event, index) => {
      const startHour = moment(event.start_time, "h:mm a").hour();
      const endHour = moment(event.end_time, "h:mm a").hour();
      const eventHeight = (endHour - startHour) * 60 - 4;
      let startFormatted = new Date(0, 0, 0, startHour, 0).toLocaleTimeString(
        [],
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      );
      let endFormatted = new Date(0, 0, 0, endHour, 0).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const isBooked = bookedSession?.some(
        (session) => session.id === event.id
      );

      return (
        <div
          key={index}
          className={isBooked ? "event is-booked" : "event"}
          style={{
            top: `${startHour * 60 + 69}px`,
            height: `${eventHeight}px`,
          }}
          onClick={() => handleEventClick(isBooked, event)}
        >
          <p className="title">{event.title}</p>
          <p className="time">
            {startFormatted} - {endFormatted}
          </p>
          <p className="price">
            <strong>Price: </strong> ${event.price}
          </p>
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faUsers} inverse />
            {event.reserved_slots} / {event.max_users}
          </div>
        </div>
      );
    });
  };

  const renderCurrentTimeIndicator = () => {
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();
    const topPosition = currentHour * 60 + currentMinute + 68;

    return (
      <div
        className="current-time-indicator"
        style={{
          top: `${topPosition}px`,
        }}
      />
    );
  };

  return (
    <div className="calendar">
      <Row type="flex" className="toolbar">
        <div>
          <Button onClick={goToToday} className="todayBtn">
            Today
          </Button>
          <Button onClick={handlePrevWeek} className="iconBtn">
            &lt;
          </Button>
          <Button onClick={handleNextWeek} className="iconBtn">
            &gt;
          </Button>
          <Button className="iconBtn">{formattedDate}</Button>
        </div>
        {isHost && (
          <div className="d-flex gap-2">
            {showAddSessionBtn && (
              <Button onClick={setShowSessionModal} className="add-new-btn">
                New +
              </Button>
            )}
            <Button
              onClick={setShowProposeSessionModal}
              className="add-new-btn"
            >
              Propose Session
            </Button>
          </div>
        )}
      </Row>

      <div className="week-view">
        <div className="time-column">
          <div className="time-slot" />
          {Array.from({ length: 24 }).map((_, index) => (
            <div key={index} className="time-slot">
              {moment().startOf("day").add(index, "hours").format("h A")}
            </div>
          ))}
        </div>
        <div className="days-column">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="day-column">
              <div
                className={`${
                  day.isSame(today, "day")
                    ? "day-header is-today"
                    : "day-header"
                }`}
              >
                <p>{day.format("ddd")}</p>
                <p>{day.format("DD")}</p>
              </div>

              <div className="day-events">
                {renderEvents(day)}
                {Array.from({ length: 24 }).map((_, hour) => (
                  <div
                    key={hour}
                    className={`hour-slot ${
                      selectionRange ? isSelectedCell(day, hour) : ""
                    }`}
                    onMouseDown={() => handleMouseDown(day, hour)}
                    onMouseEnter={() => handleMouseEnter(day, hour)}
                    onMouseUp={() => handleMouseUp()}
                  >
                    <div className="empty-event"></div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {renderCurrentTimeIndicator()}
        </div>
      </div>
    </div>
  );
};

export default MainCalendar;
