import React from "react";
import AppointmentsListing from "components/Appointments/AppointmentsListing";

const Appointments = () => {
  return (
    <div className="content-body">
      <AppointmentsListing />
    </div>
  );
};

export default Appointments;
