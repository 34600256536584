import React, { useEffect, useState } from "react";
import { useGetParticipantsQuery } from "api/sessionParticipantsApi";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import "./styles.scss";

const AppointmentsListing = () => {
  const { data, isLoading, isError, error } = useGetParticipantsQuery();
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (data && data.participants) {
      setParticipants(data.participants);
    }
    if (isError && error) {
      toast.error(
        error.data?.message || "Something went wrong. Please try again.",
        {
          position: "bottom-right",
        }
      );
    }
  }, [data, isError, error]);

  return (
    <div className="participants-container">
      <div className="header">
        <h2>Sessions Participants</h2>
        <div className="actions">
          <input
            type="text"
            placeholder="Search"
            className="form-control search-input"
          />
          <select className="form-select sort-select">
            <option>Sort by: Newest</option>
            <option>Sort by: Oldest</option>
          </select>
        </div>
      </div>
      <table className="table table-dark table-hover">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Email</th>
            <th>Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <Loader isLoading={isLoading} />
          {participants.length > 0 ? (
            participants.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="customer-info">
                    <img
                      src={item.profile_picture_url} // Replace with actual profile pictures
                      alt="profile"
                      className="profile-img"
                    />
                    {item.name}
                  </div>
                </td>
                <td>{item.email}</td>
                <td>{item.time}</td>
                <td>
                  <span className={`status ${item.status.toLowerCase()}`}>
                    {item.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                No participants available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {participants.length > 0 && data.participants.length > 10 && (
        <div className="pagination">
          <span>Showing data 1 to 8 of 10 entries</span>
          <div className="page-controls">
            <button className="btn btn-sm btn-secondary">{"<"}</button>
            <button className="btn btn-sm btn-primary">1</button>
            <button className="btn btn-sm btn-secondary">2</button>
            <button className="btn btn-sm btn-secondary">3</button>
            <button className="btn btn-sm btn-secondary">...</button>
            <button className="btn btn-sm btn-secondary">40</button>
            <button className="btn btn-sm btn-secondary">{">"}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentsListing;
