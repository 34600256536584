import React, { useState } from "react";
import { Modal, Form, InputNumber, DatePicker, Input } from "antd";
import { useCreateProposedSessionMutation } from "api/sessionApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "./styles.scss";
import CustomTimePicker from "components/CustomTimePicker";

const ProposeSessionForm = ({ show, hostId, handleClose }) => {
  const [createProposedSession, { isLoading }] =
    useCreateProposedSessionMutation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    id: "",
    host_id: hostId,
    session_date: "",
    start_time: "",
    end_time: "",
    price: 0,
    title: "",
    description: "",
    status: "pending",
  });

  const handleDateChange = (date, dateString) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      session_date: dateString,
    }));
  };

  const handleTimeChange = (timeString, inputId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [inputId]: timeString,
    }));
    form.setFieldsValue({
      [inputId]: timeString,
    });
  };

  const handleSubmit = async () => {
    try {
      await createProposedSession(formValues).unwrap();
      toast.success("Successfully proposed a session", {
        position: "bottom-right",
      });
      handleClose();
    } catch (error) {
      toast.error("Something went wrong. Try Again", {
        position: "bottom-right",
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title="Propose a Session"
      open={show}
      onCancel={handleClose}
      footer={null}
      centered
      className={"session-form"}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input
            value={formValues.title}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, title: e.target.value }))
            }
            className="custom-input"
          />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            value={formValues.description}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="custom-input"
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label="Select Date"
          name="session_date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker
            value={
              formValues.session_date
                ? dayjs(formValues.session_date, "YYYY-MM-DD")
                : null
            }
            format={"YYYY-MM-DD"}
            onChange={handleDateChange}
            className="custom-input"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Start Time"
          name="start_time"
          rules={[{ required: true, message: "Please select start time!" }]}
        >
          <CustomTimePicker
            onTimeChange={(time) => handleTimeChange(time, "start_time")}
          />
        </Form.Item>

        <Form.Item
          label="End Time"
          name="end_time"
          rules={[{ required: true, message: "Please select end time!" }]}
        >
          <CustomTimePicker
            onTimeChange={(time) => handleTimeChange(time, "end_time")}
          />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input a price!" }]}
        >
          <InputNumber
            value={formValues.price}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, price: value }))
            }
            min={0}
            step={0.01}
            className="custom-input"
            style={{ width: "100%" }}
            prefix="$"
          />
        </Form.Item>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button type="submit" className="btn submit-btn" disabled={isLoading}>
            Save
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default ProposeSessionForm;
