import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { setAuth, clearAuth } from "slices/authSlice";
import { useVerifyUserQuery } from "api/authApi";
import "bootstrap/dist/css/bootstrap.min.css";
import { PrivateRoute, PublicRoute, HostRoute } from "components/Routing";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import Home from "pages/Home";
import BottomNav from "components/BottomNav";
import Loader from "components/Loader";
import SetPassword from "pages/SetPassword";
import BrowseGamesPage from "pages/BrowseGamesPage";
import BrowseConsolePage from "pages/BrowseConsolePage";
import BrowseGenrePage from "pages/BrowseGenrePage";
import BrowseStreamingPage from "pages/BrowseStreamingPage";
import Following from "pages/Following";
import Friends from "pages/Friends";
import Profile from "pages/Profile";
import Dashboard from "pages/Dashboard";
import Appointments from "pages/Appointments";
import HostSessions from "pages/HostSessions";
import ConnectLink from "pages/ConnectLink";
import ConnectSuccess from "pages/ConnectSuccess";
import Notifications from "pages/Notifications";
import PasswordForm from "pages/PasswordForm";
import ChatUI from "components/ChatUI";
import BidInfo from "components/BidInfo";
import BidPayment from "pages/BidPayment";
import ProposedSessionPayment from "pages/ProposedSessionPayment";
import ProposedSessionInfo from "pages/ProposedSessionInfo";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useVerifyUserQuery();

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (data) {
    dispatch(
      setAuth({
        user: data.user,
        token: window.localStorage.getItem("access_token"),
      })
    );
  } else {
    window.localStorage.removeItem("access_token");
    dispatch(clearAuth());
  }

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <Router>
          <main>
            <Header />
            <div className="body">
              <Sidebar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <PublicRoute element={<Home />} restricted={false} />
                  }
                />
                <Route
                  path="/browse"
                  element={
                    <PublicRoute
                      element={<BrowseGamesPage />}
                      restricted={false}
                    />
                  }
                />
                <Route
                  path="/browse/games"
                  element={
                    <PublicRoute
                      element={<BrowseGamesPage />}
                      restricted={false}
                    />
                  }
                />
                <Route
                  path="/browse/streaming"
                  element={
                    <PublicRoute
                      element={<BrowseStreamingPage />}
                      restricted={false}
                    />
                  }
                />
                <Route
                  path="/browse/genre"
                  element={
                    <PublicRoute
                      element={<BrowseGenrePage />}
                      restricted={false}
                    />
                  }
                />
                <Route
                  path="/browse/console"
                  element={
                    <PublicRoute
                      element={<BrowseConsolePage />}
                      restricted={false}
                    />
                  }
                />
                <Route
                  path="/set-password/:token"
                  element={
                    <PublicRoute element={<SetPassword />} restricted={false} />
                  }
                />
                <Route
                  path="/profile/:profileId"
                  element={
                    <PublicRoute element={<Profile />} restricted={false} />
                  }
                />
                <Route
                  exact
                  path="/connect/link/:account_id"
                  element={<ConnectLink />}
                />
                <Route
                  exact
                  path="/connect/success/:account_id"
                  element={<ConnectSuccess />}
                />
                <Route
                  path="/following"
                  element={<PrivateRoute element={<Following />} />}
                />
                <Route
                  path="/friends"
                  element={<PrivateRoute element={<Friends />} />}
                />
                <Route
                  path="/dashboard"
                  element={<Navigate to="/dashboard/profile" replace />}
                />
                <Route
                  path="/dashboard/profile"
                  element={<PrivateRoute element={<Dashboard />} />}
                />
                <Route
                  path="/dashboard/appointments"
                  element={<HostRoute element={<Appointments />} />}
                />
                <Route
                  path="/dashboard/host-session"
                  element={<HostRoute element={<HostSessions />} />}
                />
                <Route
                  path="/dashboard/password"
                  element={<PrivateRoute element={<PasswordForm />} />}
                />
                <Route
                  path="/dashboard/messages/:chatroomId?"
                  element={<PrivateRoute element={<ChatUI />} />}
                />
                <Route
                  path="/session/:id/bid/:bidId"
                  element={<PrivateRoute element={<BidInfo />} />}
                />
                <Route
                  path="/payment/session/:id/bid/:bidId"
                  element={<PrivateRoute element={<BidPayment />} />}
                />
                <Route
                  path="/proposed-session/:id"
                  element={<PrivateRoute element={<ProposedSessionInfo />} />}
                />
                <Route
                  path="/payment/proposed-session/:id"
                  element={
                    <PrivateRoute element={<ProposedSessionPayment />} />
                  }
                />
                <Route
                  path="/notifications"
                  element={<PrivateRoute element={<Notifications />} />}
                />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </div>
            <BottomNav />
          </main>
        </Router>
      </div>
    </Elements>
  );
};

export default App;
