import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import game from "assets/images/game.png";
import streaming from "assets/images/streaming.png";
import genre from "assets/images/genre.png";
import console from "assets/images/console.jpg";
import UserTile from "./UserTile";
import { Dropdown } from "react-bootstrap";
import { useFetchBrowseUsersQuery } from "api/homeApi";
import Loader from "./Loader";
// import ClipTile from "./ClipTile";
// import { clips } from "./clipsData";

const Browse = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterValue, setFilterValue] = useState(null);
  // const [activeTab, setActiveTab] = useState("categories");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedSort, setSelectedSort] = useState("Views (High to Low)");
  const {
    data: categoryData,
    isLoading,
    refetch,
  } = useFetchBrowseUsersQuery({ type: type, value: filterValue });

  useEffect(() => {
    if (filterValue) {
      refetch();
    }
  }, [filterValue, refetch]);

  const pageName = location.pathname.split("/").filter(Boolean).pop();

  const renderFilter = (type) => {
    if (type === "clips") {
      return (
        <>
          <Dropdown className="mb-3">
            <Dropdown.Toggle variant="secondary" id="filter-dropdown">
              Filter by Language: {selectedLanguage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSelectedLanguage("English")}>
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedLanguage("Spanish")}>
                Spanish
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedLanguage("French")}>
                French
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="sort-dropdown">
              Sort by: {selectedSort}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setSelectedSort("Views (High to Low)")}
              >
                Views (High to Low)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setSelectedSort("Views (Low to High)")}
              >
                Views (Low to High)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedSort("Newest")}>
                Newest
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    } else if (type === "streaming") {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="sort-dropdown">
            Streaming Platform: {filterValue || "All"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setFilterValue("Twitch")}>
              Twitch
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Kick")}>
              Kick
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Rumble")}>
              Rumble
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (type === "genre") {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="sort-dropdown">
            Genre: {filterValue || "All"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setFilterValue("Adventure")}>
              Adventure
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Shooter")}>
              Shooter
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Sports")}>
              Sports
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Chatting")}>
              Chatting
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Simulation")}>
              Simulation
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Strategy")}>
              Strategy
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("RPG")}>
              RPG
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (type === "console") {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="sort-dropdown">
            Console: {filterValue || "All"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setFilterValue("PlayStation")}>
              PlayStation
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("Xbox")}>
              Xbox
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterValue("PC")}>
              PC
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader isLoading={isLoading} />;
    }
    // if (activeTab === "clips") {
    //   return (
    //     <div className="clips-section mt-4">
    //       <div className="row filter-and-sort justify-content-between">
    //         {renderFilter("clips")}
    //       </div>
    //       <div className="clips-list">
    //         {clips.map((clip, index) => (
    //           <div className="clip-content" key={index}>
    //             <ClipTile
    //               title={clip.title}
    //               viewers={clip.viewers}
    //               category={clip.category}
    //               image={clip.image}
    //               profileIcon={clip.profileIcon}
    //             />
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   );
    // } else if (activeTab === "categories") {
    return (
      <div className="data-section">
        <div className="row filter-and-sort justify-content-end">
          {renderFilter(pageName)}
        </div>
        <div className="tables">
          {categoryData.length > 0 ? (
            categoryData.map((item, index) => (
              <UserTile key={index} item={item} index={index} />
              // <div key={index} className="table-content">
              //   <img className="mb-2" src={img} alt={name} />
              //   <p>{name}</p>
              //   <p className="followers">100 Followers</p>
              // </div>
            ))
          ) : (
            <p className="text-white">No data found</p>
          )}
        </div>
      </div>
    );
    // }
  };

  return (
    <div className="browse-con content-body">
      <div className="browse-head">
        <div className="category" onClick={() => navigate("/browse/games")}>
          <img src={game} alt="Game" />
          <p>Game</p>
        </div>

        <div className="category" onClick={() => navigate("/browse/streaming")}>
          <img src={streaming} alt="Streaming" />
          <p>Streaming</p>
        </div>

        <div className="category" onClick={() => navigate("/browse/genre")}>
          <img src={genre} alt="Genre" />
          <p>Genre</p>
        </div>

        <div className="category" onClick={() => navigate("/browse/console")}>
          <img src={console} alt="Console" />
          <p>Console</p>
        </div>
      </div>

      {/* <div className="tabs">
        <button
          className={`tab-button ${activeTab === "categories" ? "active" : ""}`}
          onClick={() => setActiveTab("categories")}
        >
          Categories
        </button>
        <button
          className={`tab-button ${activeTab === "clips" ? "active" : ""}`}
          onClick={() => setActiveTab("clips")}
        >
          Clips
        </button>
      </div> */}
      {renderContent()}
    </div>
  );
};

export default Browse;
