import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChatroomList from "./ChatroomList";
import { useGetChatroomMessagesQuery } from "api/chatRoomApi";
import ChatBody from "./ChatBody";
import "./styles.scss";

const ChatUI = () => {
  const { chatroomId } = useParams();
  const [activeChat, setActiveChat] = useState(null);
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [showChatbox, setShowChatbox] = useState(true);
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState(chatroomId);
  const { data } = useGetChatroomMessagesQuery(roomId, {
    skip: !roomId,
  });

  useEffect(() => {
    if (chatroomId) {
      setRoomId(chatroomId);
    }
  }, [chatroomId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setShowLeftPanel(true);
        setShowChatbox(false);
      } else {
        setShowLeftPanel(true);
        setShowChatbox(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (data) {
      setActiveChat(data.chatroom);
      setMessages(data.messages);
    }
  }, [data]);

  const handleUserClick = (user) => {
    setActiveChat(user);
    setRoomId(user.id);
    setShowChatbox(true);
    if (window.innerWidth <= 767) {
      setShowChatbox(true);
      setShowLeftPanel(false);
    }
  };

  return (
    <section className="content-body py-2">
      <div className="chat-area">
        <div className="row">
          <div
            className={`col-12 col-md-5 col-lg-4 col-xl-3 left-side ${
              showLeftPanel ? "d-block" : "d-none"
            }`}
          >
            <ChatroomList
              activeChat={activeChat}
              handleUserClick={handleUserClick}
            />
          </div>
          {roomId ? (
            <ChatBody
              chatroomId={roomId}
              activeChat={activeChat}
              showChatbox={showChatbox}
              messages={messages}
              setShowChatbox={setShowChatbox}
              setShowLeftPanel={setShowLeftPanel}
              setMessages={setMessages}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default ChatUI;
