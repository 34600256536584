import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetBidInfoQuery } from "api/sessionApi";
import Loader from "components/Loader";
import Checkout from "components/Checkout";
import "./styles.scss";

const BidPayment = () => {
  const navigate = useNavigate();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.user);
  const { bidId } = useParams();
  const { data, isLoading, isError } = useGetBidInfoQuery(bidId);

  if (data?.bidder?.id !== loggedInUser.id) {
    return (
      <p className="content-body bid-info-container">
        You are not authorized to view this page.
      </p>
    );
  }

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (isError)
    return (
      <p className="content-body bid-info-container">
        Error fetching bid information.
      </p>
    );

  return (
    data && (
      <div className="content-body bid-info-container">
        <h2>Bid Information</h2>
        <p>
          <strong>Session Title:</strong> <span>{data.session.title}</span>
        </p>
        <p>
          <strong>Bidder Name:</strong>{" "}
          <span>
            {data.bidder.first_name} {data.bidder.last_mame}
          </span>
        </p>
        <p>
          <strong>Bid Amount:</strong> <span>${data.bid.bid_amount}</span>
        </p>
        <div className="bid-info-actions">
          <button
            className="btn accept-btn"
            onClick={() => setShowCheckoutModal(true)}
          >
            Proceed to Checkout
          </button>
        </div>
        {showCheckoutModal && (
          <Checkout
            show={showCheckoutModal}
            selectedSession={{ ...data.session, price: data.bid.bid_amount }}
            refetchUser={() => navigate("/")}
            handleClose={() => setShowCheckoutModal(false)}
          />
        )}
      </div>
    )
  );
};

export default BidPayment;
