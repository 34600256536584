import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useCreatePaymentMutation } from "api/paymentApi";
import { toast } from "react-toastify";
import "./styles.scss";

const Checkout = ({ show, selectedSession, refetchUser, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    country: "United States",
  });
  const [loading, setLoading] = useState(false);

  const [createPayment, { isLoading: createPaymentLoader }] =
    useCreatePaymentMutation();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      alert("Stripe is not loaded properly!");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: formData.email,
          name: formData.name,
        },
      });

      if (error) {
        setLoading(false);
        alert(error.message);
        return;
      }

      const paymentData = {
        session_id: selectedSession.id,
        amount: selectedSession.price,
        payment_method_id: paymentMethod.id,
        ...formData,
      };

      await createPayment(paymentData).unwrap();
      if (refetchUser) refetchUser();
      toast.success("Payment successful", { position: "bottom-right" });
      closeModal();
    } catch (err) {
      toast.error("Payment failed", { position: "bottom-right" });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      email: "",
      name: "",
      country: "United States",
    });

    const cardElement = elements?.getElement(CardElement);
    if (cardElement) {
      cardElement.clear();
    }
  };

  const closeModal = () => {
    resetForm();
    handleClose();
  };

  const renderSession = () => {
    const startHour = moment(selectedSession.start_time, "h:mm a").hour();
    const endHour = moment(selectedSession.end_time, "h:mm a").hour();
    let startFormatted = new Date(0, 0, 0, startHour, 0).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }
    );
    let endFormatted = new Date(0, 0, 0, endHour, 0).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const date = new Date(selectedSession.session_date);
    const formattedDate = `${date.toLocaleDateString("en-US", {
      weekday: "long",
    })}, ${date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    })}`;

    return (
      <div className="session-info">
        <p className="title">{selectedSession.title}</p>
        <p className="time">
          {formattedDate}: {startFormatted} - {endFormatted}
        </p>
        {selectedSession.co_hosts?.length > 0 && (
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faUsers} inverse />
            <div className="co-host-images">
              {selectedSession.co_hosts.map((cohost, index) => (
                <img
                  key={index}
                  className="co-host-image"
                  src={cohost.profile_picture_url}
                  alt="Co host"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={closeModal} className="checkout-modal" centered>
      <Modal.Body>
        {selectedSession && renderSession()}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Email"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="card-info" className="form-label">
              Card information
            </label>
            <div id="card-info" className="form-control p-2">
              <CardElement
                options={{
                  style: {
                    base: {
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "Arial, sans-serif",
                      "::placeholder": {
                        color: "#fff",
                      },
                    },
                    invalid: {
                      color: "#e5424d",
                    },
                  },
                  hidePostalCode: true,
                }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name on card
            </label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Full name on card"
              required
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Country or region
            </label>
            <select
              id="country"
              className="form-select"
              value={formData.country}
              onChange={handleInputChange}
            >
              <option>United States</option>
              <option>Canada</option>
              <option>United Kingdom</option>
            </select>
          </div>

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
              disabled={loading || createPaymentLoader}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!stripe || loading || createPaymentLoader}
            >
              {loading || createPaymentLoader
                ? "Processing..."
                : `Pay $${selectedSession.price}`}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Checkout;
