import React from "react";
import {
  useGetNotificationsQuery,
  useMarkAsReadMutation,
} from "api/notificationsApi";
import Loader from "components/Loader";
import moment from "moment";
import "./styles.scss";

const Notifications = () => {
  const {
    data: notifications,
    isLoading,
    isError,
  } = useGetNotificationsQuery();
  const [markAsRead] = useMarkAsReadMutation();

  const handleMarkAsRead = () => {
    markAsRead();
  };

  if (isLoading) return <Loader isLoading={isLoading} />;
  if (isError) return <div>Error loading notifications.</div>;

  return (
    <div className="notification-wrapper content-body">
      <div className="notification-panel">
        <div className="notification-header">
          <h3>Notifications</h3>
          <button className="mark-read" onClick={handleMarkAsRead}>
            Mark all as read
          </button>
        </div>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <div className="notification-item">
              <img
                src={notification.actor_profile_picture}
                alt="User profile"
                className="user-img"
              />
              <div className="notification-content">
                <div className="notification-message">
                  <p>{notification.message}</p>
                  <span className="time">
                    {moment(notification.updated_at).fromNow()}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No notifications</p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
