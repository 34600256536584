import React, { useEffect, useState } from "react";
import { useGetChatroomsQuery } from "api/chatRoomApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import sessionImage from "assets/images/session_image.png";
import Loader from "components/Loader";

const ChatroomList = ({ activeChat, handleUserClick }) => {
  const [activeTab, setActiveTab] = useState("friends");
  const [chatrooms, setChatrooms] = useState([]);
  const [filteredChatrooms, setFilteredChatrooms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { data, error, isLoading } = useGetChatroomsQuery(activeTab);

  const tabs = [
    { id: "friends", label: "Friends" },
    { id: "followers", label: "Followers" },
    { id: "sessions", label: "Sessions" },
  ];

  useEffect(() => {
    if (data) {
      setChatrooms(data);
      setFilteredChatrooms(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim()) {
      const searchResults = chatrooms.filter((record) => {
        const username = record?.receiver?.username?.toLowerCase() || "";
        const name = record?.name?.toLowerCase() || "";
        const lastMessage = record?.last_message?.content?.toLowerCase() || "";
        return (
          username.includes(searchTerm.toLowerCase()) ||
          name.includes(searchTerm.toLowerCase()) ||
          lastMessage.includes(searchTerm.toLowerCase())
        );
      });
      setFilteredChatrooms(searchResults);
    } else {
      setFilteredChatrooms(chatrooms);
    }
  }, [searchTerm, chatrooms]);

  const handleTabClick = (category) => {
    if (activeTab !== category) {
      setActiveTab(category);
      setSearchTerm("");
    }
  };

  const renderChatrooms = () => {
    if (isLoading) return <Loader isLoading={isLoading} />;
    if (error) return <p className="text-white">Error loading chatrooms</p>;
    if (filteredChatrooms.length === 0) {
      return <p className="text-white">No messages found</p>;
    }

    return filteredChatrooms.map((record) => {
      const isSession = activeTab === "sessions";
      const username = isSession ? record.name : record.receiver?.username;
      const profilePicture = isSession
        ? sessionImage
        : record.receiver?.profile_picture_url;
      const lastMessage = record.last_message?.content;

      return (
        <div
          key={record.id}
          className={`d-flex align-items-center list ${
            activeChat?.id === record?.id ? "active" : ""
          }`}
          onClick={() => handleUserClick(record)}
        >
          <div className="flex-shrink-0">
            <img
              className="user-img"
              src={profilePicture || sessionImage}
              alt={`${username} img`}
            />
          </div>
          <div className="flex-grow-1 ms-3">
            <h5>{username}</h5>
            <p className="last-msg">{lastMessage}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="chat-header">
        <div className="search-con input-group rounded-pill">
          <div className="input-group-prepend border-0">
            <button
              id="button-addon4"
              type="button"
              className="btn btn-link text-info"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} inverse />
            </button>
          </div>
          <input
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            aria-describedby="button-addon4"
            className="form-control border-0"
          />
        </div>

        <div className="wrapper">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab ${activeTab === tab.id ? "active" : ""}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className="user-lists">{renderChatrooms()}</div>
    </>
  );
};

export default ChatroomList;
