import React from "react";
import UserProfile from "components/UserProfile";

const Dashboard = () => {
  return (
    <div className="content-body">
      <UserProfile />
    </div>
  );
};

export default Dashboard;
