import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCirclePlay,
  faHeart,
  faUser,
  faRightFromBracket,
  faCalendarAlt,
  faMessage,
  faLock,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { setLoginModalVisibility } from "slices/globalSlice";
import { useLogOutMutation } from "api/authApi";
import { clearAuth } from "slices/authSlice";
import Logo from "assets/images/logo.png";
import { toast } from "react-toastify";
import "./styles.scss";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout] = useLogOutMutation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    if (location.pathname.startsWith("/browse") && path === "/browse") {
      return true;
    }

    return location.pathname === path;
  };

  const handleNavigation = (to) => {
    if (to === "/logout") {
      handleSignOut();
      return;
    }
    if (to === "/following" && !isAuthenticated) {
      dispatch(setLoginModalVisibility(true));
      return;
    }
    navigate(to);
  };

  const SidebarItem = ({ to, icon, text }) => {
    const activeClass = isActive(to) ? "nav-item active" : "nav-item";
    return (
      <li className={activeClass} onClick={() => handleNavigation(to)}>
        <FontAwesomeIcon icon={icon} />
        <span className="nav-text">{text}</span>
      </li>
    );
  };

  const handleSignOut = async () => {
    try {
      await logout().unwrap();
      dispatch(clearAuth());
      window.localStorage.removeItem("access_token");
      toast.success("Logged out successfully.", { position: "bottom-right" });
      navigate("/");
    } catch (err) {
      toast.error("Logout failed. Please try again.", {
        position: "bottom-right",
      });
    }
  };

  const renderDashboardMenu = () => (
    <ul>
      <SidebarItem to="/dashboard/profile" icon={faUser} text="My Profile" />
      {user?.is_host && (
        <>
          <SidebarItem
            to="/dashboard/appointments"
            icon={faCheck}
            text="Appointments"
          />
          <SidebarItem
            to="/dashboard/host-session"
            icon={faCalendarAlt}
            text="Session"
          />
        </>
      )}
      <SidebarItem to="/dashboard/messages" icon={faMessage} text="Messages" />
      <SidebarItem to="/dashboard/password" icon={faLock} text="Password" />
    </ul>
  );

  const renderDefaultMenu = () => (
    <ul>
      <SidebarItem to="/" icon={faHome} text="Home" />
      <SidebarItem to="/browse" icon={faCirclePlay} text="Browse" />
      <SidebarItem to="/following" icon={faHeart} text="Following" />
      {isAuthenticated && (
        <SidebarItem to="/friends" icon={faUser} text="Friends" />
      )}
    </ul>
  );

  return (
    <nav className="main-menu">
      <div>
        <div className="logo-info">
          <img src={Logo} alt="logo" />
        </div>
        {location.pathname.startsWith("/dashboard")
          ? renderDashboardMenu()
          : renderDefaultMenu()}
      </div>

      {isAuthenticated && (
        <ul>
          {!location.pathname.startsWith("/dashboard") && (
            <SidebarItem to="/dashboard/profile" icon={faUser} text="Account" />
          )}
          <SidebarItem to="/logout" icon={faRightFromBracket} text="Logout" />
        </ul>
      )}
    </nav>
  );
};

export default Sidebar;
