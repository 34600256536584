import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useResetPasswordReqMutation } from "api/authApi";
import { toast } from "react-toastify";
import "./styles.scss";

const ForgotPassword = ({ show, setShow, handleModalShow }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [resetPasswordReq, { isLoading }] = useResetPasswordReqMutation();

  useEffect(() => {
    if (!show) {
      setError("");
      setEmail("");
    }
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }

    try {
      const response = await resetPasswordReq({ user: { email } }).unwrap();
      if (response.status === "success") {
        setShow(false);
        toast.success(response.message, { position: "bottom-right" });
      } else {
        const errMsg = response.error.data;
        toast.error(
          errMsg || "Forgot Password request failed. Please try again.",
          {
            position: "bottom-right",
          }
        );
      }
    } catch (err) {
      toast.error("Forgot Password request failed. Please try again.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="forgot-password-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="forgot-password-container">
        <h1 className="forgot-password-title">Forgot Password</h1>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <div className="input-group">
            <div className="input-with-icon mt-2">
              <input
                className="form-control"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FontAwesomeIcon className="icon-envelope" icon={faEnvelope} />
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
          <button
            type="submit"
            className="forgot-password-btn"
            disabled={isLoading}
          >
            {isLoading ? "Submiting..." : "Submit"}
          </button>
          <div className="forgot-password-options">
            <button
              className="forgot-password"
              onClick={() => handleModalShow("login")}
            >
              Login
            </button>
            <button
              className="create-account"
              onClick={() => handleModalShow("register")}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPassword;
