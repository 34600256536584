import React, { useState, useEffect } from "react";
import { Modal, Form, InputNumber, Select, DatePicker, Input } from "antd";
import CustomTimePicker from "components/CustomTimePicker";
import {
  useCreateSessionMutation,
  useUpdateSessionMutation,
} from "api/sessionApi";
import { useFetchHostsQuery } from "api/homeApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "./styles.scss";

const initialFormValue = {
  id: "",
  session_date: "",
  start_time: "",
  end_time: "",
  price: 0,
  co_hosts: [],
  title: "",
  description: "",
  max_users: 1,
  status: "pending",
};

const SessionForm = ({
  show,
  handleClose,
  refetchSessions,
  selectedSession,
  formData = null,
}) => {
  const [createSession, { isLoading }] = useCreateSessionMutation();
  const [updateSession, { isUpdating }] = useUpdateSessionMutation();
  const { data: hosts = [] } = useFetchHostsQuery();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(initialFormValue);

  useEffect(() => {
    if (selectedSession) {
      setFormValues({
        id: selectedSession.id || "",
        session_date: selectedSession.session_date || "",
        start_time: selectedSession.start_time || "",
        end_time: selectedSession.end_time || "",
        price: selectedSession.price || 0,
        co_hosts: selectedSession.co_hosts || [],
        title: selectedSession.title || "",
        description: selectedSession.description || "",
        max_users: selectedSession.max_users || 1,
        status: selectedSession.status || "pending",
      });
      form.setFieldsValue({
        title: selectedSession.title,
        description: selectedSession.description,
        session_date: selectedSession.session_date
          ? dayjs(selectedSession.session_date, "YYYY-MM-DD")
          : null,
        start_time: selectedSession.start_time,
        end_time: selectedSession.end_time,
        price: selectedSession.price,
        max_users: selectedSession.max_users,
        co_hosts: selectedSession.co_hosts || [],
      });
    }
    if (formData) {
      console.log("SessionForm", formData);
      setFormValues((prev) => ({
        ...prev,
        session_date: formData.date || "",
        start_time: formData.startTime || "",
        end_time: formData.endTime || "",
      }));
      form.setFieldsValue({
        session_date: formData.date ? dayjs(formData.date, "YYYY-MM-DD") : null,
        start_time: formData.startTime,
        end_time: formData.endTime,
      });
    }
  }, [selectedSession, form, formData]);

  const handleSelectChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      co_hosts: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      session_date: dateString,
    }));
  };

  const handleTimeChange = (timeString, inputId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [inputId]: timeString,
    }));
    form.setFieldsValue({
      [inputId]: timeString,
    });
  };

  const resetForm = () => {
    setFormValues(initialFormValue);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      const isUpdate = Boolean(selectedSession);
      const sessionAction = isUpdate ? updateSession : createSession;
      const successMessage = isUpdate
        ? "Successfully updated the session"
        : "Successfully added the session";

      await sessionAction(formValues).unwrap();
      toast.success(successMessage, {
        position: "bottom-right",
      });
      handleClose();
      refetchSessions();
      resetForm();
    } catch (error) {
      toast.error("Something went wrong. Try Again", {
        position: "bottom-right",
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title="New Session"
      open={show}
      onCancel={() => {
        resetForm();
        handleClose();
      }}
      afterClose={resetForm}
      footer={
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn submit-btn"
            disabled={isLoading || isUpdating}
            onClick={async () => {
              try {
                const values = await form.validateFields();
                handleSubmit(values);
              } catch (errorInfo) {
                console.error("Validation Failed:", errorInfo);
              }
            }}
          >
            Save
          </button>
        </div>
      }
      centered
      className={"session-form"}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input
            value={formValues.title}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, title: e.target.value }))
            }
            className="custom-input"
          />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            value={formValues.description}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="custom-input"
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label="Select Date"
          name="session_date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker
            value={
              formValues.session_date
                ? dayjs(formValues.session_date, "YYYY-MM-DD")
                : null
            }
            format={"YYYY-MM-DD"}
            onChange={handleDateChange}
            className="custom-input"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Start Time"
          name="start_time"
          rules={[{ required: true, message: "Please select start time!" }]}
        >
          <CustomTimePicker
            onTimeChange={(time) => handleTimeChange(time, "start_time")}
            value={formValues.start_time}
          />
        </Form.Item>

        <Form.Item
          label="End Time"
          name="end_time"
          rules={[{ required: true, message: "Please select end time!" }]}
        >
          <CustomTimePicker
            onTimeChange={(time) => handleTimeChange(time, "end_time")}
            value={formValues.end_time}
          />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input a price!" }]}
        >
          <InputNumber
            value={formValues.price}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, price: value }))
            }
            min={0}
            step={0.01}
            className="custom-input"
            style={{ width: "100%" }}
            prefix="$"
          />
        </Form.Item>

        <Form.Item
          label="Max Users"
          name="max_users"
          rules={[{ required: true, message: "Please input max users!" }]}
        >
          <InputNumber
            value={formValues.max_users}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, max_users: value }))
            }
            min={1}
            className="custom-input"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label="Co-Hosts" name="co_hosts">
          <Select
            mode="multiple"
            showSearch
            style={{ width: "100%" }}
            placeholder="Select co-hosts"
            onChange={handleSelectChange}
            value={formValues.co_hosts}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="custom-input"
            options={(hosts || []).map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SessionForm;
