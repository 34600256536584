import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const sessionApi = createApi({
  reducerPath: "sessionApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = window.localStorage.getItem("access_token");
      if (token && endpoint !== "register") {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSessions: builder.query({
      query: () => "/sessions",
    }),
    getSessionById: builder.query({
      query: (id) => `/sessions/${id}`,
    }),
    createSession: builder.mutation({
      query: (sessionData) => ({
        url: "/sessions",
        method: "POST",
        body: sessionData,
      }),
    }),
    updateSession: builder.mutation({
      query: ({ id, ...sessionData }) => ({
        url: `/sessions/${id}`,
        method: "PATCH",
        body: sessionData,
      }),
    }),
    deleteSession: builder.mutation({
      query: (id) => ({
        url: `/sessions/${id}`,
        method: "DELETE",
      }),
    }),
    getBidInfo: builder.query({
      query: (id) => `/bids/${id}`,
    }),
    createBid: builder.mutation({
      query: (bid) => ({
        url: "/bids",
        method: "POST",
        body: bid,
      }),
    }),
    updateBid: builder.mutation({
      query: ({ id, ...bid }) => ({
        url: `/bids/${id}`,
        method: "PATCH",
        body: bid,
      }),
    }),
    getProposedSessionInfo: builder.query({
      query: (id) => `/proposed_sessions/${id}`,
    }),
    createProposedSession: builder.mutation({
      query: (proposedSession) => ({
        url: "/proposed_sessions",
        method: "POST",
        body: proposedSession,
      }),
    }),
    updateProposedSession: builder.mutation({
      query: ({ id, ...proposedSession }) => ({
        url: `/proposed_sessions/${id}`,
        method: "PATCH",
        body: proposedSession,
      }),
    }),
  }),
});

export const {
  useGetSessionsQuery,
  useGetSessionByIdQuery,
  useCreateSessionMutation,
  useUpdateSessionMutation,
  useDeleteSessionMutation,
  useCreateBidMutation,
  useUpdateBidMutation,
  useGetBidInfoQuery,
  useCreateProposedSessionMutation,
  useUpdateProposedSessionMutation,
  useGetProposedSessionInfoQuery,
} = sessionApi;
