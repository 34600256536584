import React from "react";
import SessionsListing from "components/Session/Listing";

const HostSessions = () => {
  return (
    <div className="content-body">
      <SessionsListing />
    </div>
  );
};

export default HostSessions;
