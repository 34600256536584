import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetBidInfoQuery, useUpdateBidMutation } from "api/sessionApi";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import "./styles.scss";

const BidInfo = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const { bidId } = useParams();
  const { data, isLoading, isError } = useGetBidInfoQuery(bidId);
  const [updateBid] = useUpdateBidMutation();

  const handleAction = async (actionType) => {
    try {
      await updateBid({ id: bidId, status: actionType }).unwrap();
      toast.success(`Bid ${actionType} successfully.`, {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Failed to accept bid:", error);
      toast.success(
        `Error ${
          actionType === "accepted" ? "accepting" : "rejecting"
        } bid. Please try again.`,
        {
          position: "bottom-right",
        }
      );
    }
  };

  if (data?.session_host_id !== loggedInUser.id) {
    return <p>You are not authorized to view this page.</p>;
  }

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (isError) return <p>Error fetching bid information.</p>;

  return (
    data && (
      <div className="content-body bid-info-container">
        <h2>Bid Information</h2>
        <p>
          <strong>Session Title:</strong> <span>{data.session.title}</span>
        </p>
        <p>
          <strong>Bidder Name:</strong>{" "}
          <span>
            {data.bidder.first_name} {data.bidder.last_mame}
          </span>
        </p>
        <p>
          <strong>Bid Amount:</strong> <span>${data.bid.bid_amount}</span>
        </p>
        <div className="bid-info-actions">
          <button
            className="btn accept-btn"
            onClick={() => handleAction("accepted")}
          >
            Accept Bid
          </button>
          <button
            className="btn reject-btn"
            onClick={() => handleAction("rejected")}
          >
            Reject Bid
          </button>
        </div>
      </div>
    )
  );
};

export default BidInfo;
