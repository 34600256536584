import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const PrivateRoute = ({ element, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return element;
};

const PublicRoute = ({ element, restricted, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (isAuthenticated && restricted) {
    return <Navigate to="/" replace />;
  }

  return element;
};

const HostRoute = React.memo(({ element }) => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) return <Navigate to="/" replace />;

  if (!user?.is_host) {
    if (!toast.isActive("host-restriction")) {
      toast.error(
        "You don't have access to this page. Become a host to access this page.",
        {
          position: "bottom-right",
          toastId: "host-restriction",
        }
      );
    }

    return <Navigate to="/dashboard/profile" replace />;
  }

  return element;
});

export { PrivateRoute, PublicRoute, HostRoute };
