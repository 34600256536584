import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const CustomTimePicker = ({ onTimeChange, value = null }) => {
  const [hours, setHours] = useState(12);
  const [minutes, setMinutes] = useState(0);
  const [period, setPeriod] = useState("AM");
  const [initialRender, setInitialRender] = useState(true);

  const convertTo12HourFormat = (time) => {
    let [h, m] = time.split(":").map(Number);
    let period = "AM";

    // Convert hour to 12-hour format
    if (h >= 12) {
      if (h > 12) h -= 12; // Convert hours > 12 to the 12-hour format
      period = "PM";
    } else if (h === 0) {
      h = 12; // Handle midnight as 12:00 AM
    }

    setHours(h);
    setMinutes(m);
    setPeriod(period);
  };

  useEffect(() => {
    if (value) {
      convertTo12HourFormat(value);
    }
  }, [value]);

  const incrementHours = () => {
    setHours((prev) => (prev === 12 ? 1 : prev + 1));
  };

  const decrementHours = () => {
    setHours((prev) => (prev === 1 ? 12 : prev - 1));
  };

  const incrementMinutes = () => {
    setMinutes((prev) => (prev === 59 ? 0 : prev + 1));
  };

  const decrementMinutes = () => {
    setMinutes((prev) => (prev === 0 ? 59 : prev - 1));
  };

  const togglePeriod = () => {
    setPeriod((prev) => (prev === "AM" ? "PM" : "AM"));
  };

  const handleTimeChange = () => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }

    let updatedHours = hours;
    if (period === "PM" && hours !== 12) {
      updatedHours += 12;
    } else if (period === "AM" && hours === 12) {
      updatedHours = 0;
    }
    // Format the time in 24-hour format (HH:mm)
    const formattedTime = `${updatedHours.toString().padStart(2, "0")}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
    if (onTimeChange) {
      onTimeChange(formattedTime);
    }
  };

  // Update the time whenever hours, minutes, or period changes
  useEffect(handleTimeChange, [hours, minutes, period]);

  return (
    <div className="time-picker">
      <div className="time-picker-column">
        <FontAwesomeIcon icon={faChevronUp} inverse onClick={incrementHours} />
        <span className="time-picker-value">{hours}</span>
        <FontAwesomeIcon
          icon={faChevronDown}
          inverse
          onClick={decrementHours}
        />
      </div>
      <span className="time-picker-separator">:</span>
      <div className="time-picker-column">
        <FontAwesomeIcon
          icon={faChevronUp}
          inverse
          onClick={incrementMinutes}
        />
        <span className="time-picker-value">
          {minutes < 10 ? `0${minutes}` : minutes}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          inverse
          onClick={decrementMinutes}
        />
      </div>
      <span className="time-picker-separator">:</span>
      <div className="time-picker-column">
        <FontAwesomeIcon icon={faChevronUp} inverse onClick={togglePeriod} />
        <span className="time-picker-value">{period}</span>
        <FontAwesomeIcon icon={faChevronDown} inverse onClick={togglePeriod} />
      </div>
    </div>
  );
};

export default CustomTimePicker;
