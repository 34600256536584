import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "api/authApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const validateForm = () => {
    const newErrors = {};
    if (!password) newErrors.password = "Password is required";
    if (!confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required";
    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = "Confirm Password do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await resetPassword({
        user: {
          reset_password_token: token,
          password: password,
          password_confirmation: confirmPassword,
        },
      }).unwrap();
      if (response.status === "success") {
        toast.success(response.message, { position: "bottom-right" });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        const errMsg = response.error.data;
        toast.error(errMsg || "Set Password failed. Please try again.", {
          position: "bottom-right",
        });
      }
    } catch (err) {
      toast.error("Set Password failed. Please try again.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div className="set-password">
      <div className="set-password-container">
        <h1 className="set-password-title">Set Your Password</h1>
        <form onSubmit={handleSubmit} className="set-password-form">
          <div className="input-group">
            <div className="input-with-icon mt-2">
              <input
                className="form-control"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEyeSlash}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEye}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div className="input-group">
            <div className="input-with-icon mt-2">
              <input
                className="form-control"
                type={showConfirmationPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showConfirmationPassword ? (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEyeSlash}
                  onClick={() => setShowConfirmationPassword(false)}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEye}
                  onClick={() => setShowConfirmationPassword(true)}
                />
              )}
            </div>
            {errors.confirmPassword && (
              <p className="error-message">{errors.confirmPassword}</p>
            )}
          </div>
          <button
            type="submit"
            className="set-password-btn"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
