import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, Button, Row, Col, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faPaperclip,
  faPaperPlane,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useAddMessageMutation } from "api/chatRoomApi";
import moment from "moment";
import { createConsumer } from "@rails/actioncable";
import sessionImage from "assets/images/session_image.png";

const { TextArea } = Input;

const ChatBody = ({
  chatroomId,
  activeChat,
  showChatbox,
  messages,
  setShowChatbox,
  setShowLeftPanel,
  setMessages,
}) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const [message, setMessage] = useState("");
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    const cable = createConsumer(process.env.REACT_APP_CABLE_URL);
    console.log("data", cable, process.env.REACT_APP_CABLE_URL);
    const subscription = cable.subscriptions.create(
      { channel: `ChatroomChannel`, chatroom_id: chatroomId },
      {
        received: (data) => {
          console.log("data", data, process.env.REACT_APP_CABLE_URL);
          setMessages((prevMessages) => [...prevMessages, data.message]);
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [chatroomId]);

  const [addMessage] = useAddMessageMutation();

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = async () => {
    try {
      await addMessage({ chatroomId, data: { content: message } });
      setMessage("");
    } catch (err) {}
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to={`/profile/${activeChat?.receiver?.id}`}>View Profile</Link>
      </Menu.Item>
    </Menu>
  );

  console.log("activeChat", activeChat);
  const title = activeChat
    ? activeChat.is_group
      ? activeChat.name
      : activeChat.receiver?.username
    : "";

  const profileImage = activeChat
    ? activeChat.is_group
      ? sessionImage
      : activeChat.receiver?.profile_picture_url
    : sessionImage;

  return (
    <div
      className={`col-12 col-md-7 col-lg-8 col-xl-9 ${
        showChatbox ? "d-block" : "d-none"
      } chatbox`}
    >
      <div className="chatbox">
        <div className="chat-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2">
              {showChatbox && (
                <Button
                  onClick={() => {
                    setShowChatbox(false);
                    setShowLeftPanel(true);
                  }}
                  id="close-chat-btn"
                  type="button"
                  className="btn btn-link text-info"
                >
                  <FontAwesomeIcon icon={faChevronLeft} inverse />
                </Button>
              )}
              <img
                className="user-img"
                src={profileImage}
                alt={`${title} img`}
              />
              <span>{title}</span>
            </div>
            {/* <Dropdown overlay={menu} trigger={["click"]}>
              <button
                id="button-menu"
                type="button"
                className="btn btn-link text-info"
              >
                <FontAwesomeIcon icon={faEllipsis} inverse size="xl" />
              </button>
            </Dropdown> */}
          </div>
        </div>
        <div className="msg-body" ref={messagesContainerRef}>
          <ul>
            {messages?.map((record) => (
              <li
                key={record.id}
                className={`${
                  record.sender_id === loggedInUser.id ? "repaly" : "sender"
                }`}
              >
                <div>
                  {activeChat.is_group && (
                    <p className="sender-name">
                      {record.sender_id === activeChat.admin_id
                        ? "Host"
                        : record.sender_name}
                    </p>
                  )}
                  <p>{record.content}</p>
                </div>
                <span className="time">
                  {moment(record.created_at).format("hh:mm A")}
                </span>
              </li>
            ))}
            {/* <li>
              <div className="line-with-text">
                <span>Today</span>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="message-input">
          <Row gutter={[8, 8]} align="middle">
            {/* <Col>
              <Button icon={<FontAwesomeIcon icon={faPaperclip} />} />
            </Col> */}
            <Col flex="auto">
              <TextArea
                rows={2}
                value={message}
                onChange={handleChange}
                placeholder="Type your message..."
                autoSize={{ minRows: 1, maxRows: 3 }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
              />
            </Col>
            <Col>
              <Button
                icon={<FontAwesomeIcon icon={faPaperPlane} inverse />}
                type="primary"
                onClick={handleSend}
                disabled={!message.trim()}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ChatBody;
