import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetProposedSessionInfoQuery,
  useUpdateProposedSessionMutation,
} from "api/sessionApi";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import "./styles.scss";

const ProposedSessionInfo = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const { data, isLoading, isError } = useGetProposedSessionInfoQuery(id);
  const [updateProposedSession] = useUpdateProposedSessionMutation();

  const handleAction = async (actionType) => {
    try {
      await updateProposedSession({ id: id, status: actionType }).unwrap();
      toast.success(`Proposed Session ${actionType} successfully.`, {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Failed to accept Proposed Session:", error);
      toast.success(
        `Error ${
          actionType === "accepted" ? "accepting" : "rejecting"
        } proposed session. Please try again.`,
        {
          position: "bottom-right",
        }
      );
    }
  };

  if (data?.session?.host_id !== loggedInUser.id) {
    return (
      <p className="content-body bid-info-container">
        You are not authorized to view this page.
      </p>
    );
  }

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (isError)
    return (
      <p className="content-body bid-info-container">
        Error fetching proposed session information.
      </p>
    );

  const startHour = moment(data?.session?.start_time, "h:mm a").hour();
  const endHour = moment(data?.session?.end_time, "h:mm a").hour();
  let startFormatted = new Date(0, 0, 0, startHour, 0).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  let endFormatted = new Date(0, 0, 0, endHour, 0).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    data && (
      <div className="content-body bid-info-container">
        <h2>Proposed Session Information</h2>
        <p>
          <strong>Session Title:</strong> <span>{data.session.title}</span>
        </p>
        <p>
          <strong>Initiator Name:</strong>{" "}
          <span>{data.session.initiator.name}</span>
        </p>
        <p>
          <strong>Date:</strong> <span>{data.session.session_date}</span>
        </p>
        <p>
          <strong>Time:</strong>
          <span>
            {startFormatted} - {endFormatted}
          </span>
        </p>
        <p>
          <strong>Price:</strong>
          <span>$ {data.session.price}</span>
        </p>
        <div className="bid-info-actions">
          <button
            className="btn accept-btn"
            onClick={() => handleAction("accepted")}
          >
            Accept
          </button>
          <button
            className="btn reject-btn"
            onClick={() => handleAction("rejected")}
          >
            Reject
          </button>
        </div>
      </div>
    )
  );
};

export default ProposedSessionInfo;
