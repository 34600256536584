import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookF,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const UserTile = ({ item, index }) => {
  const navigate = useNavigate();
  const fullName = item.first_name + " " + item.last_name;

  const handleClick = () => {
    navigate(`/profile/${item.id}`);
  };

  const socialMediaLinks = [
    { platform: "Instagram", urlKey: "instagram_url", icon: faInstagram },
    { platform: "Facebook", urlKey: "facebook_url", icon: faFacebookF },
    { platform: "TikTok", urlKey: "tiktok_url", icon: faTiktok },
    { platform: "Twitter", urlKey: "twitter_url", icon: faXTwitter },
    { platform: "YouTube", urlKey: "youtube_url", icon: faYoutube },
  ];

  return (
    <div
      className="card__glass"
      key={index}
      style={{
        backgroundImage: `url(${item.profile_picture_url})`,
      }}
      onClick={handleClick}
    >
      <div className="card__data">
        <h3 className="card__title">{fullName}</h3>
        <span className="card__profession">{item.username}</span>
        <Link to={`/profile/${item.id}`}>
          <FontAwesomeIcon icon={faEye} inverse />
        </Link>
      </div>

      <div className="card__social">
        {socialMediaLinks.map(({ platform, urlKey, icon }) => (
          <a
            key={platform}
            href={item[urlKey]}
            target="_blank"
            rel="noreferrer"
            className="card__link"
            onClick={(e) => e.stopPropagation()}
          >
            <FontAwesomeIcon icon={icon} inverse />
          </a>
        ))}
      </div>
      <div className="card__overlay"></div>
    </div>
  );
};

export default UserTile;
