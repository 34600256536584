import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const chatRoomApi = createApi({
  reducerPath: "chatRoomApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getChatrooms: builder.query({
      query: (activeTab) => ({ url: `/chatrooms?type=${activeTab}` }),
    }),
    createChatroom: builder.mutation({
      query: (data) => ({
        url: "chatrooms",
        method: "POST",
        body: data,
      }),
    }),
    getChatroomMessages: builder.query({
      query: (chatroomId) => ({ url: `/chatrooms/${chatroomId}/messages` }),
    }),
    addMessage: builder.mutation({
      query: ({ chatroomId, data }) => ({
        url: `/chatrooms/${chatroomId}/messages`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetChatroomsQuery,
  useCreateChatroomMutation,
  useGetChatroomMessagesQuery,
  useAddMessageMutation,
} = chatRoomApi;
