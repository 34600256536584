import React from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faUser,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import "./styles.scss";

const SessionDetail = ({
  session,
  visible,
  setShowCheckoutModal,
  setShowBidModal,
  onClose,
}) => {
  const start = dayjs(`${session.session_date}T${session.start_time}`);
  const end = dayjs(`${session.session_date}T${session.end_time}`);

  const day = start.format("dddd");
  const date = start.format("MMMM D");
  const time = `${start.format("h:mm")} – ${end.format("h:mm a")}`;

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title={null}
      centered
      className="session-detail-modal"
    >
      <div className="modal-content">
        <h3 className="modal-title">{session.title}</h3>
        <p className="description">{session.description}</p>
        <div className="modal-details">
          <div className="detail-item">
            <FontAwesomeIcon icon={faClock} size="md" />
            <span>
              {day}, {date} • {time}
            </span>
          </div>
          <div className="detail-item">
            <FontAwesomeIcon icon={faDollarSign} size="md" />
            <span>{session.price}</span>
          </div>
          <div className="detail-item guests">
            <FontAwesomeIcon icon={faUser} size="md" />
            <span>Hosts</span>
          </div>
          <div className="host-list">
            {session.host && (
              <div className="host-detail">
                <img
                  className="host-img"
                  src={session.host.profile_picture_url}
                  alt="host image"
                />
                <span className="host-info">{session.host.name}(host)</span>
              </div>
            )}
            {session.co_hosts &&
              session.co_hosts.length > 0 &&
              session.co_hosts.map((coHost, index) => (
                <div className="host-detail" key={index}>
                  <img
                    className="host-img"
                    src={coHost.profile_picture_url}
                    alt={`${coHost.name} profile`}
                  />
                  <span className="host-info">{coHost.name}(Co host)</span>
                </div>
              ))}
          </div>
          <div className="action">
            <button
              onClick={() => {
                setShowBidModal(true);
                onClose();
              }}
            >
              Place Bid
            </button>
            <button
              onClick={() => {
                setShowCheckoutModal(true);
                onClose();
              }}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SessionDetail;
