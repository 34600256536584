import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useRegisterMutation } from "api/authApi";
import { setAuth } from "slices/authSlice";
import { toast } from "react-toastify";
import "./styles.scss";

const RegisterForm = ({ show, setShow, handleModalShow }) => {
  const dispatch = useDispatch();
  const [registerUser, { isLoading }] = useRegisterMutation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
    profilePicture: null,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!show) {
      setErrors({});
      setFormData({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        termsAccepted: false,
        profilePicture: null,
      });
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else if (type === "file") {
      setFormData({ ...formData, profilePicture: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.username) newErrors.username = "Username is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!formData.termsAccepted) {
      newErrors.termsAccepted = "You must accept the terms";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataToSend = new FormData();

    formDataToSend.append("user[first_name]", formData["firstName"]);
    formDataToSend.append("user[last_name]", formData["lastName"]);
    formDataToSend.append("user[username]", formData["username"]);
    formDataToSend.append("user[email]", formData["email"]);
    formDataToSend.append("user[password]", formData["password"]);
    formDataToSend.append(
      "user[confirmation_password]",
      formData["confirmPassword"]
    );
    formDataToSend.append("user[profile_picture]", formData["profilePicture"]);

    try {
      const res = await registerUser(formDataToSend);
      if (res.data?.status === "success") {
        dispatch(setAuth({ user: res.data.user, token: res.data.token }));
        window.localStorage.setItem("access_token", res.data.token);
        toast.success(res.data.message, { position: "bottom-right" });
        setShow(false);
      } else {
        toast.error(res.error.data.message, { position: "bottom-right" });
      }
    } catch (error) {
      toast.error("An error occurred during registration.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="register-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form className="register-form" onSubmit={handleSubmit}>
        <h1>Welcome To Gameo</h1>
        <p>Create your account</p>

        <div className="form-group">
          <label>Upload Profile Picture</label>
          <input
            className="form-control"
            type="file"
            name="profilePicture"
            onChange={handleChange}
          />
          {errors.profilePicture && (
            <span className="error">{errors.profilePicture}</span>
          )}
        </div>

        <div className="form-row">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
          />
          {errors.username && <span className="error">{errors.username}</span>}
        </div>

        <div className="form-group">
          <input
            className="form-control"
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-row">
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            {errors.confirmPassword && (
              <span className="error">{errors.confirmPassword}</span>
            )}
          </div>
        </div>

        <div className="form-group terms">
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
          />
          <span>
            I have read and agree to the <a href="#">Terms & Conditions</a> and{" "}
            <a href="#">Privacy policy</a>
          </span>
          {errors.termsAccepted && (
            <span className="error">{errors.termsAccepted}</span>
          )}
        </div>

        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? "Registering..." : "Register"}
        </button>
        <p className="options">Already have an account?</p>
        <button
          type="button"
          className="login-btn"
          onClick={() => handleModalShow("login")}
        >
          Login
        </button>
        <p className="options">Or</p>
        <button type="button" className="guest-login-btn">
          Continue as guest
        </button>
      </form>
    </Modal>
  );
};

export default RegisterForm;
