import { configureStore } from "@reduxjs/toolkit";
import authReducer from "slices/authSlice";
import globalReducer from "slices/globalSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { homeApi } from "api/homeApi";
import { authApi } from "api/authApi";
import { sessionApi } from "api/sessionApi";
import { paymentApi } from "api/paymentApi";
import { profileApi } from "api/profileApi";
import { stripeApi } from "api/stripeApi";
import { notificationsApi } from "api/notificationsApi";
import { setNotificationsCount } from "slices/globalSlice";
import { sessionParticipantsApi } from "api/sessionParticipantsApi";
import { chatRoomApi } from "api/chatRoomApi";
import { toast } from "react-toastify";

const responseInterceptorMiddleware = (store) => (next) => (action) => {
  const response = action.payload;
  if (response && response.status === 401) {
    toast.error("You need to sign in or sign up before continuing.", {
      position: "bottom-right",
    });
  }
  if (response && response.notifications_count !== undefined) {
    store.dispatch(setNotificationsCount(response.notifications_count));
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [homeApi.reducerPath]: homeApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [sessionParticipantsApi.reducerPath]: sessionParticipantsApi.reducer,
    [chatRoomApi.reducerPath]: chatRoomApi.reducer,
    auth: authReducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      homeApi.middleware,
      authApi.middleware,
      sessionApi.middleware,
      paymentApi.middleware,
      profileApi.middleware,
      stripeApi.middleware,
      notificationsApi.middleware,
      sessionParticipantsApi.middleware,
      chatRoomApi.middleware,
      responseInterceptorMiddleware
    ),
});

setupListeners(store.dispatch);

export default store;
